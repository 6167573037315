.profile-dropdown {
    position: relative;
    display: inline-block;
  }
  
  .profile-button {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
  }
  
  .profile-image {
    width: 70px;
    height: 70px;
    border-radius: 100%;
    border: 5px solid white;
  }
  
  .dropdown-menu {
    position: absolute;
    right: 0;
    top: 100%;
    background: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    z-index: 1;
    display: flex;
    flex-direction: column;
    width: 150px;
  }
  
  .dropdown-item {
    padding: 10px;
    text-align: left;
    background: none;
    border: none;
    width: 100%;
    cursor: pointer;
    color: #333;
    text-decoration: none;
  }
  
  .dropdown-item:hover {
    background-color: #f1f1f1;
  }
  