/* Profile.css */
.Dashboard_main_wrapper_ov {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 20px; /* Added padding for some breathing space */
}
.page_filter_Ul{
    margin-left: 100px;
}

.box_main_border_ov {
    background: rgb(41,171,226);
    background: linear-gradient(360deg, rgba(41,171,226,1) 0%, rgba(117,117,117,0) 50%, rgba(41,171,226,1) 100%);
    padding: 2px; /* Increased padding for a better visual effect */
    border-radius: 14px;
    width: 100%; /* Make sure it takes the full width available */
    max-width: 600px; /* Add a max-width to avoid it being too wide */
    box-sizing: border-box; /* Include padding in the element's total width and height */
    margin-left: 150px;
}
@media (max-width: 367px){
    .box_main_border_ov {
        margin-left: 10px;
    }
    
}
@media (max-width: 367px){
    .page_filter_Ul{
        margin-left:30px;
        margin-right: 30px;
    }
}



.trade_now_block_ov {
    background: #070B24;
    border-radius: 14px;
    padding: 20px 30px;
}

.profile_edit_main {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Ensure it doesn't exceed its parent container */
}

.profile_edit_main h4 {
    font-size: 24px;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.profile_image_section {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
}

.profile_image_label {
    cursor: pointer;
    display: inline-block;
    border: 2px dashed #ccc;
    border-radius: 50%;
    padding: 10px;
    width: 150px;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070B24;
}

.profile_image_placeholder {
    color: #aaa;
    text-align: center;
}

.profile_image_preview {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile_details_section {
    display: flex;
    flex-direction: column;
}

.profile_details_section label {
    font-size: 16px;
    color: #fff;
    margin-bottom: 5px;
}

.profile_details_section input {
    padding: 10px;
    border: 1px solid rgb(41,171,226);;
    background: #070B24;
    border-radius: 5px;
    margin-bottom: 15px;
    font-size: 16px;
    color: #fff;
}

.profile_details_section input::placeholder {
    color: #aaa;
}

.profile_details_section input:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.profile-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.profile-header-container h4 {
    margin: 0; /* Remove default margin */
}

.profile-header-container button {
    background: linear-gradient(120.79deg, #AA14F0 21.21%, #29ABE2 98.71%);
    border-radius: 33px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 1px;
    color: #FFFFDD;
    padding: 9.5px 31px;
    display: inline-block;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    display: inline-block;
}

.submit-button {
    background: linear-gradient(120.79deg, #AA14F0 21.21%, #29ABE2 98.71%);
    border-radius: 33px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 1px;
    color: #FFFFDD;
    padding: 9.5px 31px;
    display: inline-block;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    display: inline-block;
}

.silver-button {
    background: linear-gradient(180deg,#777,hsla(0,0%,96%,.8) 35.16%,#fff 61.46%,#9c9c9c 81.77%,#e3e3e3);
    border-radius: 33px;
    font-weight: 700;
    font-size: 18px;
    line-height: 1.56;
    letter-spacing: 1px;
    color: #000;
    padding: 9.5px 31px;
    display: inline-block;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    display: inline-block;
}
